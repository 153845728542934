.App {
  text-align: center;
}
.row {
 
  width: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !important;
  color: #000;
  overflow-x: hidden;
  word-wrap: break-word;
}
.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
  float: left;
}
.navbar-nav {
  
  height: 70px;
 
}
.top-header {
  background-color: #232323;
  position: relative;
  padding: 18px 0;
}
.nav-link {
  display: block;
  padding: .5rem 1rem !important;
  align-items: center;
}
.navbar-nav li a {
  color: #232323;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.52px;
  background-color: transparent;
  word-wrap: break-word;
  white-space: normal;
}
.top-header .contact-block {
  display: inline-block;
}
.top-header .contact-block p {
  display: inline-block;
  font-size: 13px;
  letter-spacing: 0.52px;
  line-height: 28px;
  margin-bottom: 0;
  padding-right: 22px;
  color: #fff;
}
.top-header .contact-block p > a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}
.about-content p {
  color: #777;
  line-height: 26px;
  letter-spacing: 0.56px;
  margin-bottom: 15px;
  font-size: 15px;
}
 .about-content span {
  color: #f4b734;
  font-weight: 600;
}
.h3, h3 {
  font-size: 1.75rem;
}
.about-content h5 {
  color: #232323;
  font-family:  sans-serif;
  font-size: 16px;
  letter-spacing: 0.64px;
  margin-top: 0;
  margin-bottom: 25px;
  padding-top: 8px;
}
 .about-content ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 35px;
}
.about-content ul li {
  color: #232323;
  margin-bottom: 18px;
}
.entry-content ul li, .entry-content ol li {
  line-height: 22px;
}
.about-content ul li::before {
  color: #f4b734;
  content: "\f00c";
  font-family: 'FontAwesome';
  padding-right: 16px;
}

h5 {
  color: #777;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.56px;
  margin-top: 0;
  margin-bottom: 5px;
  position: relative;
  padding-left: 50px;
  text-transform: uppercase;
  opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.24s ease-in-out;
    -moz-transition: opacity 0.24s ease-in-out;
    transition: opacity 0.24s ease-in-out;
    font-size: 16px;

}
.about-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
h5::before {
  background-color: #f4bb41;
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 18px;
  height: 2px;
  width: 35px;
}

 h3 span {
  color: #0084b4;
  font-weight: 600;
}


.top-header .contact-block p > a:hover {
  color: #f4b734;
}
.navbar-nav>li {
  float: left;
}
.navbar-nav li a:hover {
  background-color: #0084b4;
  color: #fff;
  border-bottom: 2px solid #f4b734;
}
.navbar-nav li a {
  font-size: 18px;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.navbar-nav > li > a {
  margin-bottom: 25px;
  margin-top: 25px;
  padding: 8px 20px;
  position: relative;
  font-weight: 600;
}

.about-content a {
  background-color: #0084b4;
  border: 1px solid #88c6dc;
  color: #fff;
  display: inline-block;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  letter-spacing: 0.56px;
  padding: 10px 35px;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.about-content a:hover {
  background-color: transparent;
  color: #232323;
  text-decoration: none;
}
.services-section {
  background-color: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-header h3 span {
  color: #0084b4;
  font-weight: 600;
}
.section-header h3 {
  color: #232323;
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  line-height: 42px;
  letter-spacing: 1.28px;
  margin-top: 0;
  margin-bottom: 0;
}
.services-section .srv-info p {
  color: #888;
  line-height: 24px;
  letter-spacing: 0.52px;
  margin-bottom: 20px;
}
.services-section .srv-info a {
    background-color: #f4b734;
    border: 1px solid #f4b734;
    color: #232323;
    display: inline-block;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
    letter-spacing: 0.462px;
    margin-top: 10px;
    padding: 10px 40px;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
}
.services-section .srv-info a:hover {
  background-color: transparent;
  color: #f4b734;
}
.services-section .srv-box {
  background-color: #fff;
  padding: 25px 20px;
}
.services-section .srv-box h5 a, .services-section .srv-box h5 {
  color: #232323;
  font-size: 18px;
  letter-spacing: 0.429px;
  margin-top: 0;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 22px;
}
.services-section .srv-box a {
  color: #0084b4;
  font-size: 13px;
  letter-spacing: 0.429px;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.services-section .srv-box a:hover {
  color: #232323;
}
.h5, h5 {
  font-size: 30px;
} 
.footer-main {
  background-color: #232323;
  padding-top: 75px;
  padding-bottom: 60px;
  position: relative;
  float: left;
}
.footer-main .footer-address-box {
  display: inline-block;
  margin-bottom: 50px;
  width: 100%;
}
.footer-main .address-info {
  border: 1px solid #555;
  display: inline-block;
  min-height: 95px;
  padding: 22px 30px 14px;
  width: 100%;
}
.footer-main .address-info p {
  color: #e7e7e7;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.396px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  padding-left: 55px;
  text-transform: capitalize;
}
.footer-main .address-info p > i {
  background-color: #f4b734;
  color: #232323;
  display: inline-block;
  font-size: 25px;
  height: 42px;
  width: 42px;
  line-height: 42px;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0;
  vertical-align: baseline;
  text-align: center;
}
footer .widget {
  display: inline-block;
  width: 100%;
  background-color: transparent;
}
.footer-main h3.widget-title {
  border-bottom: 1px solid #0084b4;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.528px;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 15px;
  text-transform: uppercase;
}
/*.widget-title::before {
  background-color: #f4b734;
  content: "";
  display: inline-block;
  height: 3px;
  width: 31px;
  position: absolute;
  bottom: 0;
  left: 0;
}*/
.footer-main .widget p {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.429px;
  line-height: 24px;
}
.footer-main .widget_about a {
  color: #0084b4;
  display: inline-block;
  letter-spacing: 0.528px;
  line-height: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
footer .widget > ul:first-of-type {
  padding-left: 0;
}
.footer-main .widget ul.footer-social li {
  display: inline-block;
  margin-right: 10px;
  width: auto;
  border: none;
  margin: 0 10px 0 0;
  padding: 0;
}
.footer-main .widget ul.footer-social li a {
  border: 1px solid #fff;
  color: #fff;
  display: inline-block;
  height: 28px;
  width: 28px;
  line-height: 26px;
  padding: 0;
  text-align: center;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.footer-main ul.footer-social li a:hover {
  background-color: #fff;
  color: #232323;
}
.footer-main h3.widget-title {
  border-bottom: 1px solid #0084b4;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.528px;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 15px;
  text-transform: uppercase;
}
.footer-main .widget ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.footer-main .widget.widget_workinghours ul li, .footer-main .widget.widget_nav_menu ul li {
  border: none;
  margin: 0;
  padding: 0;
}
.footer-main .widget ul li {
  width: 100%;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.footer-main .widget.widget_nav_menu ul li a {
  border-bottom: 1px solid rgba(255,255,255,0.3);
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.footer-main .widget ul li a {
  font-size: 14px;
  letter-spacing: 0.429px;
  line-height: 35px;
  position: relative;
  margin-bottom: 0;
  padding-left: 15px;
}
.footer-main .widget ul li a {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.App-logo {
  height: 30vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}
.link-section li {
 
  display: flex;
}
.link-section li a:hover {
    color: #232323;
    border-bottom: 2px solid #f4b734;
    text-decoration: none;
}
.link-section li a {
  font-size: 18px;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.link-section > li > a {
  margin-bottom: 25px;
  margin-top: 25px;
  padding: 8px 20px;
  position: relative;
  font-weight: 600;
}

.link-section{
  padding:  30px 0;
}
.bottom-fixed {
  position: fixed;
  bottom: 0px;
  right: 1px;
  background-color: #886a29;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  width: 100%;
  text-align: center;
  z-index: 100000;
}
.bottom-fixed .phone {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.phone > a{
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.breadcrumb{
  margin-bottom: 2px !important;
}
.container-fluid .breadcrumb{
  padding:  0;
  margin-bottom: 0;
}
.row{
  margin-right: 0px !important;
    margin-left: 0px !important;
}
.rowClass{
  margin-right: -15px;
    margin-left: -15px;
}